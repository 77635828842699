<template>
  <div>
    <!-- <navigation/> -->
    <!-- <div style="padding-left:300px"> -->
    <div>
     
       <div class="owner-tlt">
        <v-breadcrumbs :items="items" v-if="ownerDrop">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
        <headline :selectedOwner="selectedOwner" :ownerDesc="ownerDesc" v-if="ownerDrop"/>
      </div>
      <v-content class="white maincontent">
        <!-- <div cols="12 px-0 pb-6" v-if="ownerDrop">
          <a style="margin-top: -20px">
            <v-btn text class="nocap" @click="goBack">
                  <span class="grey--text">
                     <v-icon style="margin-left:-10px">mdi-arrow-left</v-icon> {{ labels.ALL_OWN }}
                  </span>
                </v-btn>
          </a>
          <h2 class="owner-tlt">
            {{ labels.SENTIE_TIT }}
          </h2>
        </div> -->
        <div>
           <v-col cols="12" class="py-0 px-0 ">
              <h2 class="detailLabel width-200">{{ labels.OWN_DET }}</h2>
          </v-col>
          <v-col cols="12">
            <div class="d-flex">
              <div class="detailLabel width-200">{{ labels.OWN_NAME }}</div>
              <div class="pl-4">{{ ownerInfo.ownerName }}</div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="d-flex">
              <div class="detailLabel width-200">{{ labels.ORG_NAME }}</div>
              <div  class="pl-4">{{ ownerInfo.name }}</div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="d-flex">
              <div class="detailLabel width-200">{{ labels.OWN_DESC }}</div>
              <div  class="pl-4">{{ ownerInfo.desc }}</div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="d-flex">
              <div class="detailLabel width-200">{{ labels.CLIENT_ID }}</div>
              <div  class="pl-4">{{ ownerInfo.clientId }}</div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="d-flex">
              <div class="detailLabel width-200">{{ labels.OWN_USER }}</div>
              <div  class="pl-4">{{ ownerInfo.userName }}</div>
            </div>
          </v-col>
        </div>

        <!-- ----------------------------------------- -->
        <v-divider class="my-6"></v-divider>
        <div class="py-6">
          <div class="text-right pb-5">
            <v-btn
              text
              solo
              elevation='2'
              color="secondary lighten-3 text--secondary text--darken-2  py-5"
              class="nocap ml-3"
              @click.stop="openThemepopup()"
            >
              <v-icon color="secondary darken-2">
                mdi-file-edit-outline
              </v-icon>
              <span style="color: black;"> {{ labels.OWN_EDIT }} </span>
            </v-btn>
          </div>
          <div class="themeBox">
            <div class="colorPanel">
              <div class="colorLabel">{{ labels.OWN_LOGIN }}</div>
              <div class="colorClass imgBox d-flex align-center">
                <!-- <img class="rounded mx-auto d-block" style="padding: 5px;" alt="" src="resources/org/images/sentientlogo.png"> -->
                <span v-if="!ownerthemeInfo.login_logo">NOTFOUND</span>
                <v-img
                  v-show="ownerthemeInfo.login_logo"
                  alt="sentient logo"
                  class="shrink rounded mx-auto d-block"
                  style="padding: 0px"
                  :src="ownerthemeInfo.login_logo"
                  min-width="80"
                  max-width="36"
                  aspect-ratio="2"
                  contain
                ></v-img>
              </div>
            </div>

            <div class="colorPanel">
              <div class="colorLabel">{{ labels.HEADER_LOGO }}</div>
              <div class="colorClass imgBox d-flex">
                <p v-if="!ownerthemeInfo.header_logo">NOTFOUND</p>
                <v-img
                  v-show="ownerthemeInfo.header_logo"
                  alt="sentient logo"
                  class="shrink rounded mx-auto d-block"
                  style="padding: 0px"
                  :src="ownerthemeInfo.header_logo"
                  min-width="80"
                  max-width="36"
                  contain
                  aspect-ratio="2"
                ></v-img>
              </div>
            </div>
            <div class="colorPanel">
              <div class="colorLabel">{{ labels.MOBILE_LOGO }}</div>
              <div class="colorClass imgBox d-flex">
                <p v-if="!ownerthemeInfo.mobile_logo">NOTFOUND</p>
                <v-img
                  v-show="ownerthemeInfo.mobile_logo"
                  alt="sentient logo"
                  class="shrink rounded mx-auto d-block"
                  style="padding: 0px"
                  :src="ownerthemeInfo.mobile_logo"
                  min-width="80"
                  max-width="36"
                  contain
                  aspect-ratio="2"
                ></v-img>
              </div>
            </div>
            <div class="colorPanel">
              <div class="colorLabel">{{ labels.FOOTER_LOGO }}</div>
              <div class="colorClass imgBox d-flex">
                <p v-if="!ownerthemeInfo.footer_logo">NOTFOUND</p>
                <v-img
                  v-show="ownerthemeInfo.footer_logo"
                  alt="sentient logo"
                  class="shrink rounded mx-auto d-block"
                  style="padding: 0px"
                  :src="ownerthemeInfo.footer_logo"
                  min-width="80"
                  max-width="36"
                  contain
                  aspect-ratio="2"
                ></v-img>
              </div>
            </div>
            <div class="colorPanel">
              <div class="colorLabel">{{ labels.SERVICE_LOGO }}</div>
              <div class="colorClass imgBox d-flex">
                <p v-if="!ownerthemeInfo.service_logo">NOTFOUND</p>
                <v-img
                  v-show="ownerthemeInfo.service_logo"
                  alt="sentient logo"
                  class="shrink rounded mx-auto d-block"
                  style="padding: 0px"
                  :src="ownerthemeInfo.service_logo"
                  min-width="80"
                  max-width="36"
                  contain
                  aspect-ratio="2"
                ></v-img>
              </div>
            </div>
          </div>
        </div>
        <v-divider class="my-6"></v-divider>
        <div class="themeBox">
          <div class="colorPanel">
            <div class="colorLabel">{{ labels.OWN_BG }}</div>
            <div
              class="colorClass"
              :style="{ backgroundColor: ownerthemeInfo.body_background }"
            >
              {{ ownerthemeInfo.body_background }}
            </div>
          </div>
          <div class="colorPanel">
            <div class="colorLabel">{{ labels.PRI_COLOR }}</div>
            <div
              class="colorClass"
              :style="{ backgroundColor: ownerthemeInfo.primary }"
            >
              {{ ownerthemeInfo.primary }}
            </div>
          </div>
          <div class="colorPanel">
            <div class="colorLabel">{{ labels.SEC_COLOR }}</div>
            <div
              class="colorClass"
              :style="{ backgroundColor: ownerthemeInfo.secondary }"
            >
              {{ ownerthemeInfo.secondary }}
            </div>
          </div>
          <div class="colorPanel">
            <div class="colorLabel">{{ labels.TER_COLOR_1 }}</div>
            <div
              class="colorClass"
              :style="{ backgroundColor: ownerthemeInfo.tertiary_one }"
            >
              {{ ownerthemeInfo.tertiary_one }}
            </div>
          </div>
          <div class="colorPanel">
            <div class="colorLabel">{{ labels.TER_COLOR_2 }}</div>
            <div
              class="colorClass"
              :style="{ backgroundColor: ownerthemeInfo.tertiary_two }"
            >
              {{ ownerthemeInfo.tertiary_two }}
            </div>
          </div>
          <div class="colorPanel">
            <div class="colorLabel">{{ labels.ERR_COLOR }}</div>
            <div
              class="colorClass"
              :style="{ backgroundColor: ownerthemeInfo.error }"
            >
              {{ ownerthemeInfo.error }}
            </div>
          </div>
          <div class="colorPanel">
            <div class="colorLabel">{{ labels.SUCC_COLOR }}</div>
            <div
              class="colorClass"
              :style="{ backgroundColor: ownerthemeInfo.success }"
            >
              {{ ownerthemeInfo.success }}
            </div>
          </div>
          <div class="colorPanel">
            <div class="colorLabel">{{ labels.INFO_COLOR }}</div>
            <div
              class="colorClass"
              :style="{ backgroundColor: ownerthemeInfo.info }"
            >
              {{ ownerthemeInfo.info }}
            </div>
          </div>
          <div class="colorPanel">
            <div class="colorLabel">{{ labels.TXT_COLOR }}</div>
            <div
              class="colorClass"
              :style="{ backgroundColor: ownerthemeInfo.text }"
            >
              {{ ownerthemeInfo.text }}
            </div>
          </div>
          <div class="colorPanel">
            <div class="colorLabel">{{ labels.HT_COLOR }}</div>
            <div
              class="colorClass"
              :style="{ backgroundColor: ownerthemeInfo.header_text }"
            >
              {{ ownerthemeInfo.header_text }}
            </div>
          </div>
          <div class="colorPanel">
            <div class="colorLabel">{{ labels.ALERT_COLOR }}</div>
            <div
              class="colorClass"
              :style="{ backgroundColor: ownerthemeInfo.alertcolor }"
            >
              {{ ownerthemeInfo.alertcolor }}
            </div>
          </div>
          <div class="colorPanel">
            <div class="colorLabel">{{ labels.DB_COLOR }}</div>
            <div
              class="colorClass"
              :style="{
                backgroundColor: ownerthemeInfo.dash_box_border_four,
              }"
            >
              {{ ownerthemeInfo.dash_box_border_four }}
            </div>
          </div>
          <div class="colorPanel">
            <div class="colorLabel">{{ labels.DB_COLOR_2 }}</div>
            <div
              class="colorClass"
              :style="{ backgroundColor: ownerthemeInfo.dash_box_border_two }"
            >
              {{ ownerthemeInfo.dash_box_border_two }}
            </div>
          </div>
          <div class="colorPanel">
            <div class="colorLabel">{{ labels.DB_COLOR_3 }}</div>
            <div
              class="colorClass"
              :style="{ backgroundColor: ownerthemeInfo.dash_box_border_one }"
            >
              {{ ownerthemeInfo.dash_box_border_one }}
            </div>
          </div>
          <div class="colorPanel pb-6">
            <div class="colorLabel">{{ labels.DB_COLOR_4 }}</div>
            <div
              class="colorClass"
              :style="{
                backgroundColor: ownerthemeInfo.dash_box_border_three,
              }"
            >
              {{ ownerthemeInfo.dash_box_border_three }}
            </div>
          </div>
          <div class="colorPanel pb-6">
            <div class="colorLabel"></div>
            <div class="colorClass"></div>
          </div>
        </div>
        <v-divider class="my-6"></v-divider>
        <v-row class= "themeBox input-thembox colorPanel d-flex pb-4" style="justify-content: flex-start">
          <v-col class="colorLabel col-6 col-sm-2">Email ID</v-col>
          <v-col class="pl-4 col-6 col-sm-4">
            <div v-if="ownerInfo" class="nobr tertiary1--text ">{{ ownerInfo.email }}</div> 
            <div v-else class="colorClass"> 
              <a data-v-389c9281="" class= "nobr tertiary1--text" >{{ ownerthemeInfo.email}}</a>
            </div>
          </v-col>
          <v-col class="colorLabel col-6 col-sm-2">{{ labels.DOMAIN_NAME }}</v-col>
          <v-col class="col-6 col-sm-4">
            <a data-v-389c9281="" style="cursor: no-drop" href="javascript:;" class= "nobr tertiary1--text text-decoration-underline font-italic">{{ ownerthemeInfo.domain_name }}</a>
          </v-col>
        </v-row>
        <v-row class= "themeBox input-thembox colorPanel d-flex pb-4" style="justify-content: flex-start">
          <v-col class="colorLabel col-6 col-sm-2">Redis Cloud</v-col>
          <v-col class="pl-4 col-6 col-sm-4">
            <div v-if="ownerInfo" class="nobr tertiary1--text ">{{ ownerInfo.redis_cloud }}</div> 
            <div v-else class="colorClass">
              <a data-v-389c9281="" class= "nobr tertiary1--text" >{{ ownerthemeInfo.redis_cloud}}</a>
            </div>
          </v-col>
          <v-col class="colorLabel col-6 col-sm-2">User Quota</v-col>
          <v-col class="pl-4 col-6 col-sm-4">
            <div v-if="ownerInfo" class="nobr tertiary1--text ">{{ ownerInfo.user_quata }}</div> 
            <div v-else class="colorClass">
              <a data-v-389c9281="" class= "nobr tertiary1--text" >{{ ownerthemeInfo.user_quata}}</a>
            </div>
          </v-col>
        </v-row>
        <v-row class= "themeBox input-thembox colorPanel d-flex pb-4" style="justify-content: flex-start">
          <v-col class="colorLabel col-6 col-sm-2">Owner Index</v-col>
          <v-col class="col-6 col-sm-4">
            <a class="nobr tertiary1--text ">{{ ownerthemeInfo.owner_index }}</a>
          </v-col>
          <v-col class="colorLabel col-6 col-sm-2">Node Red Url</v-col>
          <v-col class="col-6 col-sm-4">
            <a class="nobr tertiary1--text">{{ ownerthemeInfo.node_red_url }}</a>
          </v-col>
        </v-row>
        <v-row class= "themeBox input-thembox colorPanel d-flex pb-4" style="justify-content: flex-start">
          <v-col class="colorLabel col-6 col-sm-2">Owner Bucket</v-col>
          <v-col class="col-6 col-sm-10">
            <a class="nobr tertiary1--text">{{ ownerthemeInfo.owner_bucket }}</a>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-form ref="form" v-model="valid">
            <v-dialog
              scrollable
              v-model="editTheme"
              persistent
              max-width="900px"
            >
              <v-card>
                <v-card-title class="thempopup">
                  <h3 class="text-left">{{ labels.EU_THEME }}</h3>
                  <v-spacer></v-spacer>
                  <v-icon @click="editTheme = false">mdi-close-circle-outline</v-icon>
                </v-card-title>
                <v-divider class="my-4 my-6"></v-divider>
                <v-card-text class="edit-v-card">
                  <div class="editlogo-sec">
                    <v-row class="pb-6">
                      <div
                        class="
                         themeBox justify-md-space-between justify-space-around
                        "
                        style="width: 100%"
                      >
                        <div class="col-6 col-sm-3 col-md-3 col-lg-3">
                          <div
                            class="d-flex flex-column position-relative"
                            
                          >
                            <label class="detailLabel py-4">{{ labels.MOBILE_LOGO }}</label>
                            <div
                              v-if="
                                !editColorTheme.mobile_logo && !mobile_logo_file
                              "
                              @dragover.prevent="dragOver"
                              @dragleave.prevent="dragLeave"
                              @drop.prevent="drop($event, 'mobile')"
                            >
                              <div
                                class="
                                 text-center d-flex-column
                                justify-center
                                fileBox
                                "
                              >
                                <v-icon class="secondary--text"> mdi-cloud-upload</v-icon>
                                <div>{{ labels.UPLOAD_ICON }}</div>
                              </div>
                              <input
                                type="file"
                                class="
                                  clicktofileUpload
                                  overflow-hidden
                                  w-px
                                  h-px
                                  opacity-0
                                "
                                @change="
                                  fileChange($event.target.files, 'mobile')
                                "
                                accept="image/*"
                              />
                            </div>
                          </div>
                          <div
                            v-if="editColorTheme.mobile_logo"
                            class=""
                            style="position: relative"
                          >
                            <button
                              class="pos close-iconPos"
                              type="button"
                              @click="clearMobile"
                              title="Remove file"
                            >
                              <v-icon color="error">mdi-close-circle-outline </v-icon>
                            </button>
                          </div>
                          <img
                            class="
                              shrink
                              rounded
                              mx-auto
                              logoBoxSize
                              fileimgWidth
                            "
                            style="padding: 0px"
                            v-if="editColorTheme.mobile_logo"
                            :src="editColorTheme.mobile_logo"
                            alt="Mobile Image"
                            contain
                            aspect-ratio="2"
                          />
                        </div>

                        <div class="col-6 col-sm-3 col-md-3 col-lg-3">
                          <div
                            class="d-flex flex-column position-relative"
                            
                          >
                            <label class="detailLabel py-4">{{ labels.LOGIN_LOGO }}</label>
                            <div
                              v-if="
                                !editColorTheme.login_logo && !login_logo_file
                              "
                              @dragover.prevent="dragOver"
                              @dragleave.prevent="dragLeave"
                              @drop.prevent="drop($event, 'logo')"
                            >
                             <div
                                class="
                                 text-center d-flex-column
                                justify-center
                                fileBox
                                "
                              >
                                <v-icon class="secondary--text"> mdi-cloud-upload</v-icon>
                                <div>{{ labels.UPLOAD_ICON }}</div>
                              </div>
                              <input
                                class="
                                  overflow-hidden
                                  clicktofileUpload
                                  w-px
                                  h-px
                                  opacity-0
                                "
                                type="file"
                                @change="
                                  fileChange($event.target.files, 'logo')
                                "
                                accept="image/*"
                              />
                            </div>
                          </div>
                          <div
                            v-if="editColorTheme.login_logo"
                            class=""
                            style="position: relative"
                          >
                            <button
                              class="pos close-iconPos"
                              type="button"
                              @click="clearLogo"
                              title="Remove file"
                            >
                              <v-icon color="error">mdi-close-circle-outline </v-icon>
                            </button>
                          </div>
                          <img
                            class="
                              shrink
                              rounded
                              mx-auto
                              fileimgWidth
                              logoBoxSize
                            "
                            style="padding: 0px"
                            v-if="editColorTheme.login_logo"
                            :src="editColorTheme.login_logo"
                            alt="Logo Image"
                            contain
                            aspect-ratio="2"
                          />
                        </div>

                        <div class="col-6 col-sm-3 col-md-3 col-lg-3">
                          <div
                            class="d-flex flex-column position-relative"
                            
                          >
                            <label class="detailLabel py-4">{{ labels.HEADER_LOGO }}</label>
                            <div
                              v-if="
                                !editColorTheme.header_logo && !header_logo_file
                              "
                              @dragover.prevent="dragOver"
                              @dragleave.prevent="dragLeave"
                              @drop.prevent="drop($event, 'header')"
                            >
                              <div
                                class="
                                 text-center d-flex-column
                                justify-center
                                fileBox
                                "
                              >
                                <v-icon class="secondary--text"> mdi-cloud-upload</v-icon>
                                <div>{{ labels.UPLOAD_ICON }}</div>
                              </div>
                              <input
                                type="file"
                                class="
                                  clicktofileUpload
                                  overflow-hidden
                                  w-px
                                  h-px
                                  opacity-0
                                "
                                @change="
                                  fileChange($event.target.files, 'header')
                                "
                                accept="image/*"
                              />
                            </div>
                          </div>
                          <div class="d-flex flex-column">
                            <div
                              v-if="editColorTheme.header_logo"
                              class=""
                              style="position: relative"
                            >
                              <button
                                class="pos close-iconPos"
                                type="button"
                                @click="clearHeader"
                                title="Remove file"
                              >
                                <v-icon color="error">mdi-close-circle-outline </v-icon>
                              </button>
                            </div>
                            <img
                              class="
                                shrink
                                rounded
                                mx-auto
                                fileimgWidth
                                logoBoxSize
                              "
                              style="padding: 0px"
                              v-if="editColorTheme.header_logo"
                              :src="editColorTheme.header_logo"
                              alt="Header Image"
                              contain
                              aspect-ratio="2"
                            />
                          </div>
                        </div>

                        <div class="col-6 col-sm-3 col-md-3 col-lg-3">
                          <div
                            class="d-flex flex-column position-relative"
                            
                          >
                            <label class="detailLabel py-4">{{ labels.FOOTER_LOGO }}</label>
                            <div
                              v-if="
                                !editColorTheme.footer_logo && !footer_logo_file
                              "
                              @dragover.prevent="dragOver"
                              @dragleave.prevent="dragLeave"
                              @drop.prevent="drop($event, 'footer')"
                            >
                             <div
                                class="
                                 text-center d-flex-column
                                justify-center
                                fileBox
                                "
                              >
                                <v-icon class="secondary--text"> mdi-cloud-upload</v-icon>
                                <div>{{ labels.UPLOAD_ICON }}</div>
                              </div>
                              <input
                                type="file"
                                class="
                                  clicktofileUpload
                                  overflow-hidden
                                  w-px
                                  h-px
                                  opacity-0
                                "
                                @change="
                                  fileChange($event.target.files, 'footer')
                                "
                                accept="image/*"
                              />
                            </div>
                          </div>
                          <div
                            v-if="editColorTheme.footer_logo"
                            class=""
                            style="position: relative"
                          >
                            <button
                              class="pos close-iconPos"
                              type="button"
                              @click="clearFooter"
                              title="Remove file"
                            >
                              <v-icon color="error">mdi-close-circle-outline </v-icon>
                            </button>
                          </div>
                          <img
                            class="
                              shrink
                              rounded
                              mx-auto
                              fileimgWidth
                              logoBoxSize
                            "
                            style="padding: 0px"
                            v-if="editColorTheme.footer_logo"
                            :src="editColorTheme.footer_logo"
                            alt="Footer Image"
                            contain
                            aspect-ratio="2"
                          />
                        </div>

                        <div class="col-6 col-sm-3 col-md-3 col-lg-3">
                          <div
                            class="d-flex flex-column position-relative"
                            
                          >
                            <label class="detailLabel py-4">{{ labels.SERVICE_LOGO }}</label>
                            <div
                              v-if="
                                !editColorTheme.service_logo &&
                                !service_logo_file
                              "
                              @dragover.prevent="dragOver"
                              @dragleave.prevent="dragLeave"
                              @drop.prevent="drop($event, 'service')"
                            >
                              <div
                                class="
                                 text-center d-flex-column
                                justify-center
                                fileBox
                                "
                              >
                                <v-icon class="secondary--text"> mdi-cloud-upload</v-icon>
                                <div>{{ labels.UPLOAD_ICON }}</div>
                              </div>
                              <input
                                type="file"
                                class="
                                  clicktofileUpload
                                  overflow-hidden
                                  w-px
                                  h-px
                                  opacity-0
                                "
                                @change="
                                  fileChange($event.target.files, 'service')
                                "
                                accept="image/*"
                              />
                            </div>
                            <div
                              v-if="editColorTheme.service_logo"
                              class=""
                              style="position: relative"
                            >
                              <button
                                class="pos close-iconPos"
                                type="button"
                                @click="clearService"
                                title="Remove file"
                              >
                                <v-icon color="error">mdi-close-circle-outline </v-icon>
                              </button>
                            </div>
                            <img
                              class="
                                shrink
                                rounded
                                mx-auto
                                fileimgWidth
                                logoBoxSize
                              "
                              style="padding: 0px"
                              v-if="editColorTheme.service_logo"
                              :src="editColorTheme.service_logo"
                              alt="Footer Image"
                              contain
                              aspect-ratio="2"
                            />
                          </div>
                        </div>
                      </div>
                    </v-row>

                    <v-divider class="py-6"></v-divider>

                    <div class="row color-picker">
                      <div class="col-12 col-sm-6">
                        <div class="d-flex align-center">
                          <div class="label-width">
                            <label class="detailLabel"
                              >{{ labels.OWN_BG }}:</label
                            >
                          </div>
                          <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <div class="d-flex align-center">
                                <div class="ma-2 colorBox">
                                  <v-btn
                                    :color="editColorTheme.body_background"
                                    dark
                                    v-on="on"
                                  >
                                  </v-btn>
                                </div>

                                <div>{{ editColorTheme.body_background }}</div>
                              </div>
                            </template>
                            <v-color-picker
                              v-model="editColorTheme.body_background"
                              mode="hexa"
                              class="mx-auto"
                              canvas-height="90px"
                            ></v-color-picker>
                          </v-menu>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="d-flex align-center">
                          <div class="label-width">
                            <label class="detailLabel">{{ labels.PRI_COLOR }}:</label>
                          </div>
                          <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <div class="d-flex align-center">
                                <div class="ma-2 colorBox">
                                  <v-btn
                                    :color="editColorTheme.primary"
                                    dark
                                    v-on="on"
                                  >
                                  </v-btn>
                                </div>

                                <div>{{ editColorTheme.primary }}</div>
                              </div>
                            </template>
                            <v-color-picker
                              v-model="editColorTheme.primary"
                              mode="hexa"
                              class="mx-auto"
                              canvas-height="90px"
                            ></v-color-picker>
                          </v-menu>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="d-flex align-center">
                          <div class="label-width">
                            <label class="detailLabel">{{ labels.SEC_COLOR }}:</label>
                          </div>
                          <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <div class="d-flex align-center">
                                <div class="ma-2 colorBox">
                                  <v-btn
                                    :color="editColorTheme.secondary"
                                    dark
                                    v-on="on"
                                  >
                                  </v-btn>
                                </div>
                                <div>
                                  {{ editColorTheme.secondary }}
                                </div>
                              </div>
                            </template>
                            <v-color-picker
                              v-model="editColorTheme.secondary"
                              mode="hexa"
                              class="mx-auto"
                              canvas-height="90px"
                            ></v-color-picker>
                          </v-menu>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="d-flex align-center">
                          <div class="label-width">
                            <label class="detailLabel">{{ labels.TER_COLOR_1 }}:</label>
                          </div>
                          <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <div class="d-flex align-center">
                                <div class="ma-2 colorBox">
                                  <v-btn
                                    :color="editColorTheme.tertiary_one"
                                    dark
                                    v-on="on"
                                  >
                                  </v-btn>
                                </div>
                                <div>
                                  {{ editColorTheme.tertiary_one }}
                                </div>
                              </div>
                            </template>
                            <v-color-picker
                              v-model="editColorTheme.tertiary_one"
                              mode="hexa"
                              class="mx-auto"
                              canvas-height="90px"
                            ></v-color-picker>
                          </v-menu>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="d-flex align-center">
                          <div class="label-width">
                            <label class="detailLabel">{{ labels.TER_COLOR_2 }}:</label>
                          </div>
                          <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <div class="d-flex align-center">
                                <div class="ma-2 colorBox">
                                  <v-btn
                                    :color="editColorTheme.tertiary_two"
                                    dark
                                    v-on="on"
                                  >
                                  </v-btn>
                                </div>
                                <div>
                                  {{ editColorTheme.tertiary_two }}
                                </div>
                              </div>
                            </template>
                            <v-color-picker
                              v-model="editColorTheme.tertiary_two"
                              mode="hexa"
                              class="mx-auto"
                              canvas-height="90px"
                            ></v-color-picker>
                          </v-menu>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="d-flex align-center">
                          <div class="label-width">
                            <label class="detailLabel">{{ labels.ERR_COLOR }}:</label>
                          </div>
                          <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <div class="d-flex align-center">
                                <div class="ma-2 colorBox">
                                  <v-btn
                                    :color="editColorTheme.error"
                                    dark
                                    v-on="on"
                                  >
                                  </v-btn>
                                </div>
                                <div>
                                  {{ editColorTheme.error }}
                                </div>
                              </div>
                            </template>
                            <v-color-picker
                              v-model="editColorTheme.error"
                              mode="hexa"
                              class="mx-auto"
                              canvas-height="90px"
                            ></v-color-picker>
                          </v-menu>
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="d-flex align-center">
                          <div class="label-width">
                            <label class="detailLabel">{{ labels.SUCC_COLOR }}:</label>
                          </div>
                          <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <div class="d-flex align-center">
                                <div class="ma-2 colorBox">
                                  <v-btn
                                    :color="editColorTheme.success"
                                    dark
                                    v-on="on"
                                  >
                                  </v-btn>
                                </div>
                                <div>
                                  {{ editColorTheme.success }}
                                </div>
                              </div>
                            </template>
                            <v-color-picker
                              v-model="editColorTheme.success"
                              mode="hexa"
                              class="mx-auto"
                              canvas-height="90px"
                            ></v-color-picker>
                          </v-menu>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="d-flex align-center">
                          <div class="label-width">
                            <label class="detailLabel">{{ labels.INFO_COLOR }}:</label>
                          </div>
                          <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <div class="d-flex align-center">
                                <div class="ma-2 colorBox">
                                  <v-btn
                                    :color="editColorTheme.info"
                                    dark
                                    v-on="on"
                                  >
                                  </v-btn>
                                </div>
                                <div>
                                  {{ editColorTheme.info }}
                                </div>
                              </div>
                            </template>
                            <v-color-picker
                              v-model="editColorTheme.info"
                              mode="hexa"
                              class="mx-auto"
                              canvas-height="90px"
                            ></v-color-picker>
                          </v-menu>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="d-flex align-center">
                          <div class="label-width">
                            <label class="detailLabel">{{ labels.TXT_COLOR }}:</label>
                          </div>
                          <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <div class="d-flex align-center">
                                <div class="ma-2 colorBox">
                                  <v-btn
                                    :color="editColorTheme.text"
                                    dark
                                    v-on="on"
                                  >
                                  </v-btn>
                                </div>
                                <div>{{ editColorTheme.text }}</div>
                              </div>
                            </template>
                            <v-color-picker
                              v-model="editColorTheme.text"
                              mode="hexa"
                              class="mx-auto"
                              canvas-height="90px"
                            ></v-color-picker>
                          </v-menu>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="d-flex align-center">
                          <div class="label-width">
                            <label class="detailLabel"
                              >{{ labels.HT_COLOR }}:</label
                            >
                          </div>
                          <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <div class="d-flex align-center">
                                <div class="ma-2 colorBox">
                                  <v-btn
                                    :color="editColorTheme.header_text"
                                    dark
                                    v-on="on"
                                  >
                                  </v-btn>
                                </div>
                                <div>
                                  {{ editColorTheme.header_text }}
                                </div>
                              </div>
                            </template>
                            <v-color-picker
                              v-model="editColorTheme.header_text"
                              mode="hexa"
                              class="mx-auto"
                              canvas-height="90px"
                            ></v-color-picker>
                          </v-menu>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="d-flex align-center">
                          <div class="label-width">
                            <label class="detailLabel">{{ labels.ALERT_COLOR }}:</label>
                          </div>
                          <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <div class="d-flex align-center">
                                <div class="ma-2 colorBox">
                                  <v-btn
                                    :color="editColorTheme.alertcolor"
                                    dark
                                    v-on="on"
                                  >
                                  </v-btn>
                                </div>
                                <div>
                                  {{ editColorTheme.alertcolor }}
                                </div>
                              </div>
                            </template>
                            <v-color-picker
                              v-model="editColorTheme.alertcolor"
                              mode="hexa"
                              class="mx-auto"
                              canvas-height="90px"
                            ></v-color-picker>
                          </v-menu>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="d-flex aligin-center">
                          <div class="label-width">
                            <label class="detailLabel"
                              >{{ labels.DB_COLOR }}:</label
                            >
                          </div>
                          <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <div class="d-flex align-center">
                                <div class="ma-2 colorBox">
                                  <v-btn
                                    :color="editColorTheme.dash_box_border_one"
                                    dark
                                    v-on="on"
                                  >
                                  </v-btn>
                                </div>
                                <div>
                                  {{ editColorTheme.dash_box_border_one }}
                                </div>
                              </div>
                            </template>
                            <v-color-picker
                              v-model="editColorTheme.dash_box_border_one"
                              mode="hexa"
                              class="mx-auto"
                              canvas-height="90px"
                            ></v-color-picker>
                          </v-menu>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="d-flex align-center">
                          <div class="label-width">
                            <label class="detailLabel"
                              >{{ labels.DB_COLOR_2 }}:</label
                            >
                          </div>
                          <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <div class="d-flex align-center">
                                <div class="ma-2 colorBox">
                                  <v-btn
                                    :color="editColorTheme.dash_box_border_two"
                                    dark
                                    v-on="on"
                                  >
                                  </v-btn>
                                </div>
                                <div>
                                  {{ editColorTheme.dash_box_border_two }}
                                </div>
                              </div>
                            </template>
                            <v-color-picker
                              v-model="editColorTheme.dash_box_border_two"
                              mode="hexa"
                              class="mx-auto"
                              canvas-height="90px"
                            ></v-color-picker>
                          </v-menu>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="d-flex align-center">
                          <div class="label-width">
                            <label class="detailLabel"
                              >{{ labels.DB_COLOR_3 }}:</label
                            >
                          </div>
                          <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <div class="d-flex align-center">
                                <div class="ma-2 colorBox">
                                  <v-btn
                                    :color="
                                      editColorTheme.dash_box_border_three
                                    "
                                    dark
                                    v-on="on"
                                  >
                                  </v-btn>
                                </div>
                                <div>
                                  {{ editColorTheme.dash_box_border_three }}
                                </div>
                              </div>
                            </template>
                            <v-color-picker
                              v-model="editColorTheme.dash_box_border_three"
                              mode="hexa"
                              class="mx-auto"
                              canvas-height="90px"
                            ></v-color-picker>
                          </v-menu>
                        </div>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="d-flex align-center">
                          <div class="label-width">
                            <label class="detailLabel"
                              >{{ labels.DB_COLOR_4 }}:</label
                            >
                          </div>
                          <v-menu :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                              <div class="d-flex align-center">
                                <div class="ma-2 colorBox">
                                  <v-btn
                                    :color="editColorTheme.dash_box_border_four"
                                    dark
                                    v-on="on"
                                  >
                                  </v-btn>
                                </div>
                                <div>
                                  {{ editColorTheme.dash_box_border_four }}
                                </div>
                              </div>
                            </template>
                            <v-color-picker
                              v-model="editColorTheme.dash_box_border_four"
                              mode="hexa"
                              class="mx-auto"
                              canvas-height="90px"
                            ></v-color-picker>
                          </v-menu>
                        </div>
                      </div>
                    </div>

                    <v-divider class="my-6"></v-divider>
                    <v-row>
                      <v-col class="col-12 col-sm-2 inputfield-sec">
                          <div class="d-flex">
                            <div class="flex-fill">
                              <label class="detailLabel">{{ labels.DOMAIN_NAME }}</label>
                            </div>
                          </div>
                      </v-col>
                      <v-col class="col-12 col-sm-10 pl-0">
                        <v-text-field
                          v-model="editColorTheme.domain_name"
                          outlined
                          text
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="ownerDrop">
                        <v-col class="col-6 col-sm-2 inputfield-sec">
                            <div class="d-flex">
                              <div class="flex-fill">
                                <label class="detailLabel">Email ID</label>
                              </div>
                            </div>
                        </v-col>
                        <v-col class="col-6 col-sm-4 pl-0">
                          <v-text-field
                            v-model="editColorTheme.email"
                            outlined
                            text
                          ></v-text-field>
                        </v-col>
                        <v-col class="col-6 col-sm-2 inputfield-sec pl-2">
                          <div class="d-flex">
                            <div class="flex-fill">
                              <label class="detailLabel">Redis Cloud</label>
                            </div>
                          </div>
                        </v-col>
                        <v-col class="col-6 col-sm-4 pl-0">
                          <v-select
                            outlined
                            v-model="editColorTheme.redis_cloud"
                            :items="redis_provider"
                            item-text="value"
                            item-value="code"
                            placeholder="Select Category"
                          ></v-select>
                        </v-col>
                    </v-row>
                    <v-row v-if="ownerDrop">
                      <v-col class="col-6 col-sm-2 inputfield-sec">
                          <div class="d-flex">
                            <div class="flex-fill">
                              <label class="detailLabel">User Quota</label>
                            </div>
                          </div>
                      </v-col>
                      <v-col class="col-6 col-sm-4 pl-0">
                        <v-text-field
                          v-model="editColorTheme.user_quata"
                          oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                          outlined
                          text
                        ></v-text-field>
                      </v-col>
                      <v-col class="col-6 col-sm-2 inputfield-sec pl-2">
                          <div class="d-flex">
                            <div class="flex-fill">
                              <label class="detailLabel">Owner Index</label>
                            </div>
                          </div>
                      </v-col>
                      <v-col class="col-6 col-sm-4 pl-0">
                        <v-text-field
                          v-model="editColorTheme.owner_index"
                          outlined
                          text
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="ownerDrop">
                      <v-col class="col-6 col-sm-2 inputfield-sec" >
                          <div class="d-flex">
                            <div class="flex-fill">
                              <label class="detailLabel">Node Red URL</label>
                            </div>
                          </div>
                      </v-col>
                      <v-col class="col-6 col-sm-4 pl-0">
                        <v-text-field
                          v-model="editColorTheme.node_red_url"
                          outlined
                          text
                        ></v-text-field>
                      </v-col>
                      <v-col class="col-6 col-sm-2 inputfield-sec pl-2" >
                          <div class="d-flex">
                            <div class="flex-fill">
                              <label class="detailLabel">Owner Bucket</label>
                            </div>
                          </div>
                      </v-col>
                      <v-col class="col-6 col-sm-4 pl-0">
                        <v-text-field
                          v-model="editColorTheme.owner_bucket"
                          outlined
                          text
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="px-10 py-6">
                  <v-spacer></v-spacer>
                  <v-btn
                    color="secondary darken-1"
                    class="nocap mr-3"
                    text
                    @click="editTheme = false"
                  >
                    {{ labels.OWN_CANCEL }}
                  </v-btn>
                  <v-btn
                    color="tertiary2 darken-1 white--text"
                    class="nocap ml-3"
                    @click="saveTheme('S')"
                  >
                    {{ labels.OWN_UPDATE }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-form>
        </v-row>
      </v-content>
    </div>
    <loaderimg v-if="loader"/>
  </div>
</template> 
<script>
// import navigation from "./navigation.vue";
import loaderimg from "./loaderimg.vue"
import API_Util from '../services/util/API_Util.js';
import headline from "./headline.vue";
export default {
  components: {
    loaderimg,
    headline,
  },
  selectedOwner: "",
  ownerDesc: "",
  
  name: "tertiary",
  delimiters: ["${", "}"],
  data: () => ({
    ownerName: "",
    color: "#1976D2FF",
    mask: "!#XXXXXXXX",
    menu: false,
    filelist: [],
    singleSelect: false,
    selected: [],
    show1: false,
    password: "Password",
    editTheme: false,
    url: null,
    selectedFile: null,
    isSelecting: false,
    ownerInfo: {},
    redis_cloud: "",
    user_quata:"",
    email: "",
    redis_provider: [],
    ownerthemeInfo: {},
    editColorTheme: {},
    items: [
      {
        text: "Owners",
        disabled: false,
        href: "/ownerlisting",
      },
      {
        text: "",
        disabled: true,
        href: "/ownerlisting",
      },
      {
        text: "Platform Setting - Owner Settings",
        disabled: true,
        href: "",
      },
    ],
    login_logo_file: "",
    header_logo_file: "",
    mobile_logo_file: "",
    footer_logo_file: "",
    service_logo_file: "",
    urlObject: {},
    isFileUpload: false,
    apiKey: "",
    labels: {},
    lang: 'EN',
    loader: true,
    owner: "",
    ownerType: "",
    ownerDrop: "",
  }),

  computed: {
    buttonText() {
      return this.selectedFile
        ? this.selectedFile.name
        : this.defaultButtonText;
    },
    swatchStyle() {
      const { color, menu } = this;
      return {
        backgroundColor: color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },
  methods: {
    changeOwner(obj) {
      this.selectedOwner = obj.ownerName;
      this.ownerDesc = obj.desc;
      localStorage.setItem('ownerDesc', obj.desc);
    },
   
    goBack() {
      localStorage.setItem('visit', 0);
      setTimeout(this.$router.go(-1), 1000);
    },
    clearMobile() {
      this.editColorTheme.mobile_logo = "";
      this.mobile_logo_file = "";
    },
    clearLogo() {
      this.editColorTheme.login_logo = "";
      this.login_logo_file = "";
    },
    clearHeader() {
      this.editColorTheme.header_logo = "";
      this.header_logo_file = "";
    },
    clearFooter() {
      this.editColorTheme.footer_logo = "";
      this.footer_logo_file = "";
    },
    clearService() {
      this.editColorTheme.service_logo = "";
      this.service_logo_file = "";
    },
   
    // allOwners() {
    //   this.$router.push("/ownerlisting", () => { window.location.reload()});
    // },
    uploadIcons(file, name) {
      const fd = new FormData();
      fd.append("fileToUpload", file, file.name);
      API_Util.URLS.axios
        .post(API_Util.URLS.cdnImageUpload, fd, {
          headers: {
            mimeType: "multipart/form-data",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => {
          this.urlObject[name] = response.data.url;
        })
        .catch((err) => console.log(err));
    },
    fileChange(files, input) {
      if (input === "mobile") {
        this.editColorTheme.mobile_logo = URL.createObjectURL(files[0]);
        this.mobile_logo_file = files[0];
      }
      if (input === "logo") {
        this.editColorTheme.login_logo = URL.createObjectURL(files[0]);
        this.login_logo_file = files[0];
      }
      if (input === "header") {
        this.editColorTheme.header_logo = URL.createObjectURL(files[0]);
        this.header_logo_file = files[0];
      }
      if (input === "footer") {
        this.editColorTheme.footer_logo = URL.createObjectURL(files[0]);
        this.footer_logo_file = files[0];
      }
      if (input === "service") {
        this.editColorTheme.service_logo = URL.createObjectURL(files[0]);
        this.service_logo_file = files[0];
      }
    },
    saveTheme(type) {
      if (this.urlObject && type == "U") {
        if ("login" in this.urlObject) {
          this.editColorTheme.login_logo = this.urlObject["login"];
        }
        if ("header" in this.urlObject) {
          this.editColorTheme.header_logo = this.urlObject["header"];
        }
        if ("mobile" in this.urlObject) {
          this.editColorTheme.mobile_logo = this.urlObject["mobile"];
        }
        if ("footer" in this.urlObject) {
          this.editColorTheme.footer_logo = this.urlObject["footer"];
        }
        if ("service" in this.urlObject) {
          this.editColorTheme.service_logo = this.urlObject["service"];
        }
      }
      API_Util.URLS.axios
        .post(API_Util.URLS.updateOwnerSettings, this.editColorTheme, { headers: { "x-api-key": this.apiKey } })
        .then((response) => {
          console.log(response);
          if (type == "S") {
            // $scope.showUpdate=true;
            this.isFileUpload = false;
            var time = 1000;
            if (
              this.login_logo_file != undefined &&
              this.login_logo_file != ""
            ) {
              this.uploadIcons(this.login_logo_file, "login");
              this.isFileUpload = true;
            }
            if (
              this.header_logo_file != undefined &&
              this.header_logo_file != ""
            ) {
              this.uploadIcons(this.header_logo_file, "header");
              this.isFileUpload = true;
              time = 2000;
            }
            if (
              this.mobile_logo_file != undefined &&
              this.mobile_logo_file != ""
            ) {
              this.uploadIcons(this.mobile_logo_file, "mobile");
              this.isFileUpload = true;
              time = 3000;
            }
            if (
              this.footer_logo_file != undefined &&
              this.footer_logo_file != ""
            ) {
              this.uploadIcons(this.footer_logo_file, "footer");
              this.isFileUpload = true;
              time = 4000;
            }
            if (
              this.service_logo_file != undefined &&
              this.service_logo_file != ""
            ) {
              this.uploadIcons(this.service_logo_file, "service");
              this.isFileUpload = true;
              time = 4000;
            }
          }
          var self = this;
          if (this.isFileUpload) {
            setTimeout(function () {
              self.saveTheme("U");
              location.reload(true);
            }, time);
          } else {
            location.reload(true);
          }
          this.editTheme = false;
          this.ownerSettingsInfo();
        });
    },
    openThemepopup() {
      this.editTheme = true;
      this.editColorTheme = JSON.parse(JSON.stringify(this.ownerthemeInfo));
    },

    ownerSettingsInfo() {
      var det = {"owner_id": localStorage.getItem("owner")}
      API_Util.URLS.axios
        .post(API_Util.URLS.listOwnerSettings, det, {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.ownerthemeInfo = response.data;
          if (response.data.id==undefined){
            this.ownerthemeInfo.owner_id=localStorage.getItem("owner");
            this.ownerthemeInfo.id=0;
            this.ownerthemeInfo.user_quata="";
          }
        });
    },
    getLogo(img) {
      var asset = img.substring(0, img.lastIndexOf("/"));
      img = img.split("/")[img.split("/").length - 1].replace(/\.[^/.]+$/, "");
      var imgpath = "@/" + asset + "/";
      var path = require.context(imgpath, false, /\.png$/);
      return path("./" + img + ".png");
    },
    ownerSettingsOnload() {
      API_Util.URLS.axios
        .post(API_Util.URLS.infoOwner,
        {
          owner_id:localStorage.getItem("owner")
        }, 
        {
          headers: { "x-api-key": this.apiKey },
        })
        .then((response) => {
          this.loader = false;
          this.ownerInfo = response.data.results;
          this.items[1].text = "Sentient.io (" + this.ownerInfo.ownerName + ")";
        });
    },
    remove(i) {
      this.selectedFile.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event, type) {
      event.preventDefault();
      this.fileChange(event.dataTransfer.files, type); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`,
    },
    getFlexi(){
      API_Util.URLS.axios
      .post(API_Util.URLS.getlblbyscr,
      {
        owner_id : localStorage.getItem("owner"),
        scrId : "OWNER_SET",
        lang : this.lang
      },
      { headers: { "x-api-key" : this.apiKey } }
      )
      .then((response) => {
        this.labels = response.data.labels;
      })
    },
    async getProvider() {
      var data = {
        owner_id: localStorage.getItem("owner"),
        lang: "EN",
        code: "CLOUD_PROVIDER",
      };
      let response = await API_Util.URLS.axios
        .post(API_Util.URLS.flexyListValue, data, {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "x-api-key": this.apiKey,
          },
        })
        .then((response) => response.data.result)
        .catch((err) => console.log(err));
      this.redis_provider = response;
      console.log(response)
    },
  },
  created: function () {
    localStorage.setItem('visit', 0);
    this.loader = true;
    var sessionObj = this.$session.get("user_session");
    this.apiKey = sessionObj.session.userApiKey;
    this.ownerName = sessionObj.session.ownerName;
    this.ownerType = localStorage.getItem("ownerType");
    this.ownerDrop = JSON.parse(localStorage.getItem('ownerDrop'));
    this.user_id = sessionObj.session.id;
    this.owner = localStorage.getItem("owner");
    this.ownerSettingsInfo();
    this.ownerSettingsOnload();
    this.getFlexi();
    this.getProvider();
    this.selectedOwner = localStorage.getItem("ownerName");
    this.ownerDesc = localStorage.getItem('ownerDesc');
  },
};
</script>
<style lang="scss">
.mr-md {
  margin-bottom: -30px;
}
.add.v-icon.v-icon {
  font-size: 18px;
}

.colorPanel {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.colorLabel {
  font-weight: bold;
  padding: 0px 0px 0px 18px !important;
  display: flex;
  width: 210px;
  text-align: left;
}
.colorClass {
  width: 100px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 2.5;
}
.imgBox {
  border: 1px solid #aaaaaa;
}
.position-relative {
  position: relative !important;
}
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -29px;
  min-width: 100px;
  max-width: 100px;
  left: 10px;
}
.close-icon {
  position: absolute !important;
  top: 0px;
  right: 0px;
}
#preview img {
  max-width: 100%;
  max-height: 500px;
}
// label {
//   margin-top: 20px;
// }
.fileBox {
  min-width: 100px;
  height: 119px;
  border: 1px grey dashed;
  background-color: aliceblue;
  border-radius: 4px;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.pos {
  position: absolute;
  right: -13px;
  top: -13px;
}
.mdi-cloud-upload {
  font-size: 38px !important;
}
.colorPicker {
  .v-input__append-inner {
    div {
      cursor: pointer;
      height: 24px !important;
      width: 84px !important;
      border-radius: 0 !important;
      transition: border-radius 200ms ease-in-out 0s;
      margin: 5px;
      border: 1px solid #060606;
    }
  }
  .v-text-field > .v-input__control > .v-input__slot:before {
    border-style: none !important;
    border-bottom-color: none !important;
    border: none;
  }
  .v-text-field__slot {
    display: none !important;
  }
}
.themeBox {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  // justify-content: space-between;
  .v-responsive__sizer {
    padding: 24% !important;
  }
}
.label-width {
  width: 200px;
}
.colorBox {
  border: 1px solid #d7d7d7;
  padding: 5px;
}
.clicktofileUpload {
  position: absolute;
  top: 0;
  width: 135px;
  height: 140px;
}
.fileimgWidth {
  max-width: 80px;
  min-width: 80px;
  padding: 0px;
}
.color-picker {
  .v-size--default {
    min-width: 50px;
    border-radius: 0;
  }
  .v-btn {
    height: 25px !important;
    min-width: 50px !important;
  }
}
// This is not the good practice, to refactor in future
.v-menu__content {
    max-height: 500px !important;
}
.logoBoxSize {
  // max-width: 80px !important;
  max-height: 64px !important;
}
.close-iconPos {
  position: absolute;
  // bottom: 80px;
  right: 0;
  // height: 135px;
}
.width-200{
  min-width: 200px !important;
}
.editlogo-sec {
  padding: 40px;
}
.thempopup {
  padding: 24px 40px 10px !important;
}
.inputfield-sec {
  margin-top: 24px;
  padding: 0;
}
@media (min-width: 1440px) {
  .container {
    max-width: 1400px;
  }
}
@media (max-width: 1264px) {
  .container {
    max-width: 100%;
  }
}
/* ---------------------------------- mobile view ---------------------------------- */
@media only screen and (min-width: 350px) and (max-width: 767px) {
  .editlogo-sec {
  padding: 5px;
}
.editlogo-sec .px-4 {
  width: 50% !important;
  padding-left: 4px !important;
}
.inputfield-sec .d-flex{
  display: block !important;
}
.inputfield-sec .v-text-field--outlined {
  width: 100% !important;
}
.thempopup {
  padding: 24px 20px 10px !important;
}
.thempopup h3 {
  font-size: 20px;
}
.label-width {
    width: 140px !important;
}
.edit-v-card {
  padding: 0 10px 20px !important;
}
.inputfield-sec {
  margin-top: 10px;
  padding: 0 12px;
}
.text-clr{
  color: #000000de;
}
.input-thembox .colorPanel{
  display: block !important;
  padding-bottom: 10px !important;
}
}
</style>
<style lang="scss" scoped>
.detailLabel {
  min-width: 175px !important;
  font-weight: bold;
  padding-right:25px;

}
// .v-menu__content {
//   top: 236px !important;
// }
</style>