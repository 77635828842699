<template>
  <div class="org-print">
    <v-container>
      <!-- Username & Last active -->
      <v-row>
        <v-col class="col-12 col-md-6">
          <v-row class="mt-2">
            <span class="col-6 nowrap">
              <v-icon class="mr-0" color="var(--v-primarytextcolor-darken4)">mdi-account</v-icon>
              <b>
                {{labels.ADD_USERNAME}}
              </b>
            </span>
            <span class="col-6 text-break">
              {{ currentEditingUser.details.user_name }}
            </span>
          </v-row>
        </v-col>
        <v-col class="mt-4 mt-md-0">
          <v-row class="mt-2">
            <span class="col-6 nowrap">
              <v-icon class="mb-1 mr-0" color="var(--v-primarytextcolor-darken4)">mdi-account-clock</v-icon> 
              <b>
                {{labels.OD_LST_ACT}}
              </b>
            </span>
            <span class="col-6 pl-0" v-if="currentEditingUser.details.last_activity">
              {{ currentEditingUser.details.last_activity }}
            </span>
            <span class="col-6 pl-4" v-else>
              {{ labels.NIL }}
            </span>
          </v-row>
        </v-col>
      </v-row>

      <!-- Tabs for User Groups, User Functions, Security Credentials -->
      <v-row class="mt-4">
        <v-toolbar elevation="0" class="grey lighten-4">
          <v-tabs
            v-model="editUser_Tab"
            class="s-border-bottom"
            slider-color="tertiary1 opc-5"
            slider-size="99"
          >
            <v-tab class="nocap s-border-right col-4">
              {{labels.OD_USR_GROUPS}}
            </v-tab>
            <v-tab class="nocap s-border-right col-4">
              {{labels.OD_USR_MANFUN}}
            </v-tab>
            <v-tab class="nocap col-4">
              {{labels.OD_USR_SECU}}
            </v-tab>
          </v-tabs>
        </v-toolbar>
      </v-row>

      <!-- Tab Items for User Groups, User Functions, Security Credentials -->
      <v-row>
        <!-- --------------- -->
        <!-- User Groups Tab -->
        <!-- --------------- -->
        <v-tabs-items
          transition="fade-transition"
          class="col-12 grey lighten-5 this__edit-user-container"
          v-model="editUser_Tab"
        >
          <!-- ---------------- -->
          <!-- Edit user groups -->
          <!-- key will map values inside editUserTabItems -->
          <v-tab-item key="user groups">
            <v-container style="padding-top:0">
              <!-- ------------- -->
              <!-- Assign Groups -->
              <!-- Container for assigned groups -->
              <v-row class="d-flex justify-center mt-4">
                <v-chip small v-for="group in selectedGroups" :key="group.id" class="mx-1 secondary lighten-2 secondary--text text--darken-3">
                  {{ group.group_name }}
                </v-chip>
              </v-row>
              <v-row
                class="flex-column-reverse flex-sm-row d-flex justify-center align-center "
              >
                <!-- List to assign groups to user -->
                <div>
                  <!-- <span v-if="organisation.groups.length==currentEditingUser.groupDetails.length">All groups are assigned</span> -->
                  <v-menu offset-y :close-on-content-click="false" v-if="organisation.groups.length!=currentEditingUser.groupDetails.length">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn outlined v-bind="attrs" v-on="on" class="nocap field-spacer">
                        {{labels.OD_USR_PLS_SELGROUP}}
                        <v-icon style="margin:0">
                          mdi-menu-down
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list class="editList">
                      <!-- <v-list-item v-for="(item, index) in items" :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item> -->

                      <!-- Getting list of groups from organisation.groups -->
                      <!-- On toggle, store selected groups name in an array - selectedGroup -->
                      <v-list-item
                        color="tertiary1"
                        v-for="group in organisation.groups"
                        :key="group.id"
                        v-show="!checkExisting(group.group_name,currentEditingUser.groupDetails)"
                      >
                        <v-checkbox
                          v-model="selectedGroups"
                          color="tertiary1"
                          :value="group"
                        >
                          <template v-slot:label>
                            <span class="black--text">
                              {{ group.group_name }}
                            </span>
                          </template>
                        </v-checkbox>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>

                <!-- Assign groups button -->
                <div v-if="organisation.groups.length!=currentEditingUser.groupDetails.length">
                  <v-btn
                    color="var(--v-tertiary1-base)"
                    dark
                    class="my-4 mx-2 nocap"
                    elevation="0"
                    @click="assignGroups()"
                  >
                    <v-icon>
                      mdi-account-multiple-plus
                    </v-icon>
                    {{labels.OD_USR_ASSNGROUP}}
                    <v-progress-circular
                      :width="3"
                      :size="15"
                      color="white"
                      indeterminate
                      class="ml-1"
                      v-if="loader"
                    ></v-progress-circular>
                  </v-btn>
                </div>
              </v-row>
              <!-- -* ABOVE *- Assign Groups -->
              <!-- ------------------------- -->
              <v-data-table
                :mobile-breakpoint="0"
                disable-sort
                :headers="userGroupsTableHeader"
                :items="currentEditingUser.groupDetails"
                item-key="name"
                class="elevation-0 s-table-dark-gray s-table"
              >
                <template v-slot:[`item.remove`]="{ item }">
                  <div class="d-flex justify-center">
                    <v-icon
                      @click="removeGroupId=item;toggleConfirm('removeGroupFromUser')"
                      color="primary darken-1"
                    >
                      mdi-account-multiple-remove
                    </v-icon>
                  </div>
                </template>
              </v-data-table>
            </v-container>
            <v-row> </v-row>
          </v-tab-item>
          <!-- -* ABOVE *- User Groups Tab -->
          <!-- =========================== -->

          <!-- ------------------ -->
          <!-- User Functions Tab -->
          <!-- ------------------ -->
          <v-tab-item key="user functions">
            <UserFunctions class="pa-8" />
          </v-tab-item>
          <!-- -ABOVE- User Functions Tab -->
          <!-- ========================== -->

          <!-- ------------------------ -->
          <!-- Security Credentials Tab -->
          <!-- ------------------------ -->
          <v-tab-item key="security credentials">
            <UserSecurityCredentials class="manageUserCrd" />
          </v-tab-item>
          <!-- -ABOVE- Security Credentials Tab -->
          <!-- ================================ -->
        </v-tabs-items>
      </v-row>
    </v-container>

    <!-- ----------------------------------- -->
    <!-- Remove User From Group Confirmation -->
    <!-- ----------------------------------- -->
    <ConfirmBox
            box-name="removeGroupFromUser"
            :on-confirm-value="removeGroupId"
            :box-title="labels.USR_GRP_EDIT_CONF"
            :box-content="labels.USR_GRP_CONFORM"
            box-class="error-dialog"
            :confirm-btn-text="labels.OD_DEL"
            :cancel-btn-text="labels.ADD_CANCEL"
            confirm-btn-icon="mdi-delete"
            :button-loader="false"
        >
        </ConfirmBox>
    
    <!-- -* ABOVE *- Remove User From Group Confirmation -->
    <!-- =============================================== -->
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
// Services
import { organisationService } from '../../services/organisationService'
// Components
import Dialog from '../ui/Dialog'
import UserFunctions from './UserFunctions.vue'
import UserSecurityCredentials from './UserSecurityCredentials'
import ConfirmBox from "../ui/ConfirmBox";

export default defineComponent({
  components: {
    Dialog,
    UserFunctions,
    UserSecurityCredentials,
    ConfirmBox
  },
  setup() {
    const {
      organisation,
      currentEditingUser,
      getGroupList,
      groupsList,
      getuserInfo,
      addusertogroup,
      showDialog,
      toggleConfirm,
      confirmBoxs
} = organisationService()

    // Variables for user operation data
    const confirmingRemoveUserFromGroup = ref(false),
      groupToBeRemove = ref(null),
      groupToBeRemoveId = ref(null),
      // below variables sounds like actions, add "_" to differentiate
      editUser_Tab = 'User Groups',
      // Final group list to assign to user
      selectedGroups = ref([]),
      assignGroupsAlert = ref(false)

    // Table header for editing user group table
    const userGroupsTableHeader = ref([]);

    //**GET LABELS */
    const labels=ref({});
    setTimeout(() => {
        labels.value=organisation.labels;
        userGroupsTableHeader.value=[
          {
        text: labels.value.OD_GRP,
        align: 'left',
        value: 'value'
      },
      //{ text: 'Description', align: 'left', value: '' },
      { text: labels.value.OD_RE_FR_GRP, align: 'center', value: 'remove' }
        ]
    }, 1000);
    const removeGroupId=ref();
    const groupList = ref();
    const getAllgroupList = async() => {
          await getGroupList();
          groupList.value = groupsList.allGroupList;
      }
      getAllgroupList();

    const getUserInfo = async() => {
          await getuserInfo();
      }
      //getUserInfo();
      

    const confirmRemoveFromGroup = (group) => {
      // Toggle Remove User From Group Confirmation
      confirmingRemoveUserFromGroup.value = true
      // Assign local varialbe to hold group data temporarily
      groupToBeRemove.value = group.value;
      groupToBeRemoveId.value = group.id;
    }

    const cancelRemoveUserFromGroup = () => {
      
      confirmingRemoveUserFromGroup.value = false
      // Reset local variable
      groupToBeRemove.value = null
    }

    const loader=ref(false);
    const assignGroups = async() => {
      loader.value=true;
      await addusertogroup(selectedGroups);
      setTimeout(() => {
        loader.value=false;
      }, 3000);
      // assignGroupsAlert.value = true
      // Reset / Empty selectedGroups
      selectedGroups.value = []
    }

    const checkExisting = (name,dataarray) => {
      var isExist=false;
      if(dataarray!=undefined){
        dataarray.forEach((grp)=>{
          if(name==grp.value){
            isExist=true;
          }
        })
      }
      return isExist;
    }


    return {
      organisation,
      editUser_Tab,
      userGroupsTableHeader,
      // When user click remove user from group button, store the group name in below variable
      groupToBeRemove,
      groupToBeRemoveId,
      confirmRemoveFromGroup,
      // Control remove user confirmation pup up window
      confirmingRemoveUserFromGroup,
      cancelRemoveUserFromGroup,
      // Details for currently editing user, appear in the Edit User popup window
      currentEditingUser,
      // Select groups to assign to user
      selectedGroups,
      assignGroups,
      assignGroupsAlert,
      getAllgroupList,
      groupList,
      getUserInfo,
      showDialog,
      labels,
      toggleConfirm,
      confirmBoxs,
      removeGroupId,
      checkExisting,
      loader
    }
  }
})
</script>

<style lang="scss" scoped>
.this__edit-user-container {
  max-height: 50vh;
  overflow-y: scroll;
}
.manageUserCrd {
  padding: 32px;
}
/* ---------------------------------- mobile view ---------------------------------- */

@media only screen and (min-width: 350px) and (max-width: 767px) {
  .manageUserCrd {
    padding: 5px;
  }
}
@media only screen and (max-width: 600px){
  .field-spacer{
    margin-bottom: 10px;
  }
  .text-break{
    width:100%;
    
  }
}
</style> 