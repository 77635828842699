<template>
<div class="v-application--wrap">
 <Header @menuClicked="menuClick"/>
  <div class="d-flex Page-BG flex-column page-center mt-64" style="height: 100%">
    <div class="flex-fill">
      <navigation @menuClicked="menuClick" v-bind:class="{ 'close-drawer': menuDrawer }" v-bind:ownerType="ownerType"/>
      <v-container  v-bind:class="{ 'close-menu': !menuDrawer }">
      <router-view @ownerchange="ownerchange"></router-view>
      </v-container>
    </div>
    <!-- <Footer  v-bind:class="{ 'close-menu': !menuDrawer }"/> -->
  </div>
</div>
</template> 

<script>
import Header from "./Header.vue";
import navigation from "./navigation.vue";
//import Footer from "./Footer.vue";
//import ownerlisting from "./ownerlisting.vue";
// import ownersettings from "./ownersettings.vue";
export default {
  name: "content",
  components: {
    Header,
    navigation,
    //Footer,
    //ownerlisting,
    // ownersettings,
  },
  data: () => ({
menuDrawer:true,
ownerType:localStorage.getItem('ownerType')
  }),
methods:{
    ownerchange(type){
      this.ownerType=type;
    },
    menuClick(){
      this.menuDrawer=!this.menuDrawer;
    }
  },

    ownersettings:false,
};
</script>
<style lang="scss">
.close-drawer{
  left:-280px;
}
.close-menu{
  padding-left: 280px;
}
.head-section {
  position: absolute !important;
  top: 0 !important;
}
.container--fluid {
  max-width: 100%;
}
</style>
